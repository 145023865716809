<!-- System: STA
    Purpose: This compoment will display archived team 
            members table in grid view
-->
<template>
  <v-row>
    <v-col
      cols="12"
      md="3"
      lg="3"
      v-for="member in filtered"
      class="px-sm-16 mt-10"
    >
      <!-- Team Member Card -->
      <v-card
        class="mx-auto team-mem-box"
        max-width="300"
        style="min-height: 100% !important"
        elevation="10"
      >
        <!-- Team Member Image -->
        <img
          v-if="member.image == null"
          src="../../assets/images/no-member.svg"
          @error="
            $event.target.src = require('../../assets/images/no-member.svg')
          "
          class="img-fluid proj-img mt-2"
          height="70"
        />
        <img
          v-else
          :src="`${url}storage/${member.image}`"
          @error="
            $event.target.src = require('../../assets/images/no-member.svg')
          "
          height="70"
          alt="Thumbnail"
          class="img-fluid proj-img mt-2"
          style="
            border-radius: 100%;
            max-width: 65px;
            max-height: 65px;
            border: none;
          "
        />
        <h3 class="text-center">{{ member.name }}</h3>
        <!-- Team Member Action Buttons -->
        <v-card-text class="d-flex justify-center">
          <v-chip-group
            class="text-center"
            v-model="selection"
            active-class="deep-purple accent-4 white--text"
            column
          >
            <!-- Restore Member Buttons -->
            <v-icon
              class="icon-color"
              data-trigger="edit_profile_member"
              size="23"
              @click="restore(member)"
            >
              mdi-restore
            </v-icon>
            <!-- Delete Member Buttons -->
            <v-icon
              class="icon-color"
              size="23"
              data-trigger="edit_profile_member"
              @click="deleteMem(member)"
            >
              mdi-delete-outline
            </v-icon>
          </v-chip-group>
        </v-card-text>
      </v-card>
    </v-col>
    <div class="text-center">
      <!--------------------------  Restore Dailog Box ----------------------->
      <v-dialog
        v-model="restoreDialog"
        width="500"
        transition="dialog-top-transition"
      >
        <v-card>
          <v-toolbar color="primary" class="headline" dark
            >Are you sure?</v-toolbar
          >
          <v-card-text class="mt-4">
            <h4>
              If you restore the member, all data against member will be
              restored.
            </h4>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" outlined text @click="restoreDialog = false">
              Cancel
            </v-btn>
            <v-btn
              style="
                background-color: #2758f6 !important;
                color: #ffffff !important;
              "
              outlined
              text
              @click="(restoreDialog = false), restoreMember('restore')"
            >
              Restore
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <!--------------------------  Delete Dailog Box ----------------------->
    <div class="text-center">
      <v-dialog
        v-model="deleteDialog"
        width="500"
        transition="dialog-top-transition"
      >
        <v-card>
          <v-toolbar color="primary" class="headline" dark
            >Are you sure?</v-toolbar
          >
          <v-card-text class="mt-4">
            <h4>
              We are sorry to hear you’d like to delete the member. All the data
              associated with the member will no longer be accessible.
            </h4>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" outlined text @click="deleteDialog = false">
              Cancel
            </v-btn>
            <v-btn
              style="
                background-color: #ff6b6b !important;
                color: #ffffff !important;
              "
              outlined
              text
              @click="(deleteDialog = false), deleteMember('delete')"
            >
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <Snackbar
      :snackbar.sync="snackbar"
      :message="message"
      :color="snackbarColor"
    />
  </v-row>
</template>
<script>
import _ from "lodash";
import { min_to_hour } from "@/assets/js/utils";
import { baseURL } from "@/assets/js/urls";
import Snackbar from "@/components/includes/Snackbar";
export default {
  name: "TeamMembers",
  components: {
    Snackbar,
  },
  props: ["members"],
  data() {
    return {
      selection: "",
      restoreDialog: false,
      deleteDialog: false,
      restore_member: "",
      deletemember: "",
      message: "",
      snackbarColor: "",
      snackbar: false,
    };
  },
  computed: {
    /**
     * Return team members
     */
    filtered() {
      return this.members;
    },
    /**
     * Return base url of api
     */
    url() {
      return baseURL.API_URL;
    },
    /**
     * Return all socket employee from store
     */
    socket_employee() {
      return this.$store.state.custom.all_store_socket_employee;
    },
  },
  filters: {
    activeProjectName(name) {
      return name.length > 15
        ? name
            .slice(0, 6)
            .concat("...")
            .concat(name.slice(name.length - 6))
        : name;
    },
  },
  methods: {
    /**
     * This function is resposible for
     * converting mins to hours
     */
    convert_mins_to_hour(minutes) {
      let time = min_to_hour(minutes);
      let hour = time.hrs < 10 ? "0" + time.hrs : time.hrs;
      let mins = time.mins < 10 ? "0" + time.mins : time.mins;
      return hour + ":" + mins;
    },

    activeProject(members) {
      if (!members || !this.socket_employee) {
        return "";
      }
      console.log("socket_employee", this.socket_employee);
      let member = this.socket_employee.find(
        (user) => user.user_id === members.id
      );
      member = member && member.project ? member.project : "";
      return member;
    },
    /**
     * This function is resposible for trigger
     * restore member modal
     */
    restore(member) {
      this.restoreDialog = true;
      this.restore_member = member;
    },
    /**
     * This function is resposible for trigger
     * delete member modal
     */
    deleteMem(member) {
      this.deleteDialog = true;
      this.deletemember = member;
    },
    /**
     * This function is resposible for
     * delete member  (api call)
     */
    deleteMember() {
      let member = this.deletemember;
      if (member.member_status === "active") return;
      let data = {
        member_id: member.id,
        company_id: this.$route.params.id,
        is_archived: true,
      };
      this.$store.commit("custom/set_loader", true);
      this.$store
        .dispatch("custom/delete_member", data)
        .then((response) => {
          this.$store.commit("custom/toggle_loader", false);
          if (response.data.error === false) {
            this.snackbar = true;
            this.message = "You have successfully deleted a member";
            this.snackbarColor = "green";
            this.$store.commit("custom/toggle_progress_loader", false);
            this.$store.dispatch("custom/get_archived_team_members_list", {
              company_id: this.$route.params.id,
            });
            this.$store.dispatch("custom/get_active_team_members_list", {
              company_id: this.$route.params.id,
            });
          }
        })
        .catch(() => {
          this.$store.commit("custom/toggle_loader", false);
        });
    },
    /**
     * This function is resposible for
     * restore member  (api call)
     */
    restoreMember() {
      let member = this.restore_member;
      console.log("member", member);
      if (member.member_status === "active") return;
      let data = {
        member_id: member.id,
        company_id: this.$route.params.id,
      };
      this.$store.commit("custom/set_loader", true);
      this.$store
        .dispatch("custom/restore_member", data)
        .then((response) => {
          this.$store.commit("custom/toggle_loader", false);
          if (response.data.error === false) {
            this.snackbar = true;
            this.message = "You have successfully restored a member";
            this.snackbarColor = "green";
            this.$store.commit("custom/set_loader", false);
            this.$store.dispatch("custom/get_archived_team_members_list", {
              company_id: this.$route.params.id,
            });
            this.$store.dispatch("custom/get_active_team_members_list", {
              company_id: this.$route.params.id,
            });
          }
        })
        .catch(() => {
          this.$store.commit("custom/toggle_loader", false);
        });
    },
  },
};
</script>


<style scoped>
.team-mem-box {
  border-radius: 16px;
  background-color: #ffffff;
  box-shadow: 0 10px 30px -20px #242e4c;
}
</style>
